import "./Footer.scss";
import yt from "../images/youtube.svg";
import fb from "../images/facebook.svg";
import ig from "../images/instagram.svg";
import { NavLink } from "react-router-dom";
import AppStore from "../images/App Store - Filled.png";
import GooglePlay from "../images/Google Play.png";
import { useTranslation } from "react-i18next";
import NetKgTracker from "../NetKgTracker";

const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <footer className="footer">
      <div className="footer__content container">
        {/* <div className='footer__logo'>
          <img src={Logo} alt="transperency" />
        </div> */}
        <div className="footer__menu">
          <ul className="footer__list">
            <p style={{ fontWeight: "500", margin: "0", padding: "0" }}>
              {t("footer.nav")}
            </p>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/"
              >
                {t("footer.main")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/petitions"
              >
                {t("footer.petitions")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/analytics"
              >
                {t("footer.analytics")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/about"
              >
                {t("footer.about")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/privacy"
              >
                {t("footer.privacy")}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="footer__contacts">
          <p style={{ fontWeight: "500" }}>{t("footer.contacts")}</p>
          <p>admin@kgroad.org</p>
          <p>+9960312394038</p>
          <div>
            <a href="https://www.youtube.com/@TransparencyKyrgyzstan" target="_blank" rel="noopener noreferrer">
              <img src={yt} alt="youtube" />
            </a>
            <a href="https://www.facebook.com/tikyrgyzstan" target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/transparencyinternational_kg?igsh=cTc2MHEwOW5vNDh2" target="_blank" rel="noopener noreferrer">
              <img src={ig} alt="instagram" />
            </a>
          </div>
          <div>
          <br />
          {/*<NetKgTracker />*/} {/* PROD: only for production */}
          <br />
          </div>

        </div>
        <div className="footer__apps">
          <h5>{t("footer.download")}</h5>
          <div>
            <a href="https://apps.apple.com/us/app/bilem/id6504497737" target="_blank" rel="noopener noreferrer">
              <img src={AppStore} alt="app-store" />
            </a>
          </div>
          <div>
            <a href="https://play.google.com/store/apps/details?id=fishrungames.askjournal" target="_blank" rel="noopener noreferrer">
              <img src={GooglePlay} alt="google-play" />
            </a>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
